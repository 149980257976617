import { HubConnectionBuilder } from "@microsoft/signalr";
import { BASE_URL_API } from "../config";

export default {
  // called by Vue.use(FirstPlugin)
  async install(Vue) {
    const connection = new HubConnectionBuilder()
      .withUrl(`${BASE_URL_API}leilaohub`)
      .withAutomaticReconnect()
      // .configureLogging(LogLevel.Trace)
      .build();

    let startedPromise = null;
    function start() {
      startedPromise = connection.start().catch(() => {
        return new Promise((resolve, reject) =>
          setTimeout(() => start().then(resolve).catch(reject), 5000)
        );
      });
      return startedPromise;
    }
    connection.onclose(() => start());

    start();
    //Vue.prototype.$signalR = connection

    const leilaoHub = new Vue();
    // every component will use this.$questionHub to access the event bus
    Vue.prototype.$signalR = leilaoHub;
    // Forward server side SignalR events through $questionHub, where components will listen to them
    connection.on("NewLance", (loteId) => {
      leilaoHub.$emit("new-lance", loteId);
    });

    leilaoHub.joinLeilao = (leilaoId) => {
      return startedPromise
        .then(() => connection.invoke("JoinLeilao", leilaoId))
        .catch();
    };
  },
};
