import http from "@/service/http";

const state = {
  lanceLoading: false,
  lanceError: null,
  historicoLances: [],
  historicoLancesLoading: false,
  isSignal: false,
};

const mutations = {
  SET_LANCE_LOADING: (state, value) =>
    value ? (state.lanceLoading = value) : (state.lanceLoading = false),
  SET_LANCE_ERROR: (state, value) =>
    value ? (state.lanceError = value) : (state.lanceError = false),
  SET_HISTORICO_LANCES: (state, value) =>
    value ? (state.historicoLances = value) : (state.historicoLances = {}),
  SET_HISTORICO_LANCES_LOADING: (state, value) =>
    value
      ? (state.historicoLancesLoading = value)
      : (state.historicoLancesLoading = false),
  SET_SIGNAL: (state, value) =>
    value ? (state.isSignal = value) : (state.isSignal = false),
};

const getters = {
  getLanceLoading: (state) => state.lanceLoading,
  getLanceError: (state) => state.lanceError,
  getHistoricoLances: (state) => state.historicoLances,
  getHistoricoLancesLoading: (state) => state.historicoLancesLoading,
  getSignal: (state) => state.isSignal,
};

const actions = {
  lance({ commit }, value) {
    commit("SET_LANCE_LOADING", true);

    return http({
      url: `Lote/${value.loteId}/Lance`,
      method: "POST",
      data: value,
    })
      .then(() => {
        commit("SET_LANCE_ERROR");
        commit("SET_LANCE_LOADING");
      })
      .catch((error) => {
        const result = [];
        const errors = error.response.data.Errors;
        for (var key in errors) {
          result.push(errors[key]);
        }
        commit("SET_LANCE_ERROR", result);
        commit("SET_LANCE_LOADING");
      });
  },
  setHistoricoLances({ commit }, value) {
    // console.log(value)
    commit("SET_HISTORICO_LANCES_LOADING", true);

    http({
      url: `Lote/${value.loteId}/Lances?&PageSize=${value.pageSize}&PageNumber=${value.pageNumber}`,
      method: "get",
    })
      .then((resp) => {
        commit("SET_HISTORICO_LANCES_LOADING");
        commit("SET_HISTORICO_LANCES", resp.data);
      })
      .catch(() => {
        commit("SET_HISTORICO_LANCES");
        commit("SET_HISTORICO_LANCES_LOADING");
      });
  },
};

export default {
  state,
  mutations,
  getters,
  actions,
};
