import httpId from "@/service/http-id.js";
import http from "@/service/http.js";
import { ID_URL } from "../../config";
// import jwt from "jsonwebtoken";
import router from "../../router";
const state = {
  auth: null,
  info: null,
  loginLoading: false,
  loginError: "",
  leilao: null,
  clientApp: null,
};

const mutations = {
  SET_AUTH: (state, value) =>
    value ? (state.auth = value) : (state.auth = null),
  SET_INFO: (state, value) =>
    value ? (state.info = value) : (state.info = null),
  SET_LOGIN_LOADING: (state, value) =>
    value ? (state.loginLoading = value) : (state.loginLoading = false),
  SET_LOGIN_ERROR: (state, value) =>
    value ? (state.loginError = value) : (state.loginError = ""),
  SET_LEILAO: (state, value) =>
    value ? (state.leilao = value) : (state.leilao = null),
  SET_CLIENT_APP: (state, value) =>
    value ? (state.clientApp = value) : (state.clientApp = null),
};

const getters = {
  getAuth: (state) => state.auth,
  getInfo: (state) => state.info,
  getLoginLoading: (state) => state.loginLoading,
  getLoginError: (state) => state.loginError,
  getFotoUsuarioLogado: (state) =>
    state.info
      ? state.info.picture
        ? Array.isArray(state.info.picture)
          ? state.info.picture[state.info.picture.length - 1].split("=")[0] ||
            state.info.picture[state.info.picture.length - 1]
          : state.info.picture
        : ""
      : "",
  getLeilao: (state) => state.leilao,
  getClientApp: (state) => state.clientApp,
};

const actions = {
  async logout({ commit }) {
    commit("SET_LEILAO");
    commit("SET_AUTH");
    commit("SET_INFO");
    commit("SET_CLIENT_APP");
    router.push({ name: "Login" });
  },
  setAuth({ commit }) {
    const data = new URLSearchParams();
    data.append("client_id", "leilao-client");
    data.append("client_secret", "leilao-secret");
    data.append("grant_type", "client_credentials");
    return httpId
      .post("connect/token", data)
      .then((response) => {
        commit("SET_AUTH", response.data);
      })
      .catch(() => {});
  },

  gInfo({ commit }) {
    return new Promise((resolve, reject) => {
      http
        .get(`${ID_URL}connect/userinfo`)
        .then((resp) => {
          commit("SET_INFO", resp.data);
          resolve();
        })
        .catch(function () {
          reject();
        });
    });
  },

  async loginPassword({ commit }, value) {
    commit("SET_LOGIN_LOADING", true);
    commit("SET_CLIENT_APP", "adminebl");
    commit("SET_LOGIN_ERROR", "");
    const data = new URLSearchParams();
    data.append("client_id", "admin-eblonline");
    data.append("client_secret", "X3l5h0UhDy7F0xE2sMpSPREcTyqgtZNO");
    data.append("grant_type", "password");
    data.append("scope", "openid profile leilao.api");
    data.append("username", value.email);
    data.append("password", value.senha);

    return new Promise((resolve, reject) => {
      httpId
        .post("connect/token", data)
        .then(async (response) => {
          const auth = { ...response.data };

          commit("SET_AUTH", auth);
          commit("SET_LOGIN_ERROR", "");

          // router.push(value.returnUrl || { name: "index" });
          commit("SET_LOGIN_LOADING");

          resolve();
        })
        .catch(function (error) {
          commit("SET_LOGIN_LOADING");
          if (
            error.response.data &&
            error.response.data["error_detail"] === "email_not_confirmed"
          ) {
            commit("SET_LOGIN_ERROR", "Email não confirmado");
            reject();
          } else if (
            error.response.data &&
            error.response.data.error_description ===
              "invalid_username_or_password"
          ) {
            commit("SET_LOGIN_ERROR", "Usuário ou Senha inválidos");
            reject();
          } else {
            commit("SET_LOGIN_ERROR", error.response.data.error_description);
            reject();
          }
        });
    });
  },

  refreshToken({ commit, state }, value) {
    //console.log(value);
    const data = new URLSearchParams();
    data.append("client_id", "admin-eblonline");
    data.append("client_secret", "X3l5h0UhDy7F0xE2sMpSPREcTyqgtZNO");
    data.append("grant_type", "refresh_token");
    data.append("refresh_token", value);

    httpId.post("connect/token", data).then(async (response) => {
      const auth = {
        ...state.auth,
        access_token: response.data.access_token,
      };
      commit("SET_AUTH", auth);
    });
  },
};

export default {
  state,
  mutations,
  getters,
  actions,
};
