import Vue from "vue";
import VueRouter from "vue-router";
import Loading from "../views/Loading";
import Store from "../store";
import middleware from "./middleware";

import Login from "../views/Login";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "index",
    meta: { requiresAuth: false, clientSite: false, clientAdmin: false },
    component: Loading,
  },
  {
    path: "/painel-comitente",
    name: "PainelComitente",
    meta: { requiresAuth: true, clientSite: false, clientAdmin: true },
    component: () => import("../views/painel-comitente/"),
  },
  {
    path: "/painel-leiloeiro",
    name: "PainelLeiloeiro",
    meta: { requiresAuth: true, clientSite: false, clientAdmin: true },
    component: () => import("../views/painel-leiloeiro/"),
  },
  {
    path: "/painel-youtube",
    name: "PainelYoutube",
    meta: { requiresAuth: true, clientSite: false, clientAdmin: true },
    component: () => import("../views/youtube/"),
  },
  {
    path: "/auditorio-virtual",
    name: "AuditorioVirtual",
    meta: { requiresAuth: true, clientSite: true, clientAdmin: false },
    component: () => import("../views/painel-usuario/"),
  },
  {
    path: "/aovivo",
    name: "aovivo",
    meta: { requiresAuth: true, clientSite: true, clientAdmin: false },
    component: () => import("../views/painel-usuario/transmissao.vue"),
  },
  {
    path: "/login",
    name: "Login",
    meta: { requiresAuth: false, clientSite: false, clientAdmin: false },
    component: Login,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(function (to, _, next) {
  if (to.matched.some((route) => route.meta.requiresAuth)) {
    if (
      Store.getters.getAuth &&
      middleware(Store.getters.getAuth.access_token)
    ) {
      if (
        to.matched.some((route) => route.meta.clientSite) &&
        Store.getters.getClientApp !== "siteebl"
      ) {
        Store.dispatch("logout");
      }

      if (
        to.matched.some((route) => route.meta.clientAdmin) &&
        Store.getters.getClientApp !== "adminebl"
      ) {
        Store.dispatch("logout");
      }
      next();
    } else {
      Store.dispatch("logout");
      next();
    }
  } else {
    next();
  }
});

export default router;
