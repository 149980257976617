<template>
  <v-container fluid class="fill-height">
    <v-overlay :value="true">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-container>
</template>

<script>
export default {};
</script>

<style></style>
