export let BASE_URL_API;
export let ID_URL;
export let BASE_URL_API2;
export let ORIGIN;
export let ORIGIN2;

if (process.env.NODE_ENV == "development") {
  BASE_URL_API = "https://localhost:4001/";
  BASE_URL_API2 = "https://localhost:4001/";
  ID_URL = "https://localhost:5001/";
  ORIGIN = "http://localhost:8080";
  ORIGIN2 = "https://localhost:8080";
} else {
  BASE_URL_API = "https://hml.api.eblonline.com.br/";
  BASE_URL_API2 = "https://hml.api.eblonline.com.br/";
  ID_URL = "https://hml.id.eblonline.com.br/";
  ORIGIN = "http://hml.eblonline.com.br";
  ORIGIN2 = "https://hml.eblonline.com.br";
}
