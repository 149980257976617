import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

import Auth from "./modules/auth";
import Leiloeiro from "./modules/Leiloeiro";
import lance from "./modules/lance";

Vue.use(Vuex);
const dataState = createPersistedState({
  paths: ["Auth", "Leiloeiro"],
});
export default new Vuex.Store({
  plugins: [dataState],
  state: {
    tituloPagina: "",
  },
  getters: {},
  mutations: {
    setTituloPagina: (state, value) =>
      value ? (state.tituloPagina = value) : (state.tituloPagina = null),
  },
  actions: {},
  modules: { Auth, Leiloeiro, lance },
});
