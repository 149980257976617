import http from "@/service/http.js";

const state = {
  acao: null,
  loteAtivo: null,
  acaoAtiva: null,
};

const getters = {
  getAcao: (state) => state.acao,

  getAcaoLoteAtivo: (state) => {
    if (!state.acao || !state.loteAtivo || !state.acaoAtiva) return;
    return state.acao[state.loteAtivo]
      ? state.acao[state.loteAtivo].status
      : null;
  },
};

const mutations = {
  setAcao: (state, value) => {
    if (!value) return;

    if (state.acao == null) state.acao = {};
    state.acao[`${value.id}`] = { status: value.status };
  },
  setLoteAtivo: (state, value) => {
    if (!value) return;
    state.loteAtivo = value;
  },
  setAcaoAtiva: (state, value) => (value ? (state.acaoAtiva = value) : null),
};

const actions = {
  async iniciar(data, loteId) {
    return new Promise((resolve, reject) => {
      http
        .post(`PainelControle/IniciarFechamento/${loteId}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },
  async parar(data, loteId) {
    return new Promise((resolve, reject) => {
      http
        .post(`PainelControle/PararFechamento/${loteId}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },
  async encerrar(data, loteId) {
    return new Promise((resolve, reject) => {
      http
        .post(`PainelControle/EncerrarLote/${loteId}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },
};

export default {
  state,
  mutations,
  getters,
  actions,
};
