<template>
  <v-container fluid class="background fill-height">
    <v-card elevation="10" class="mx-auto my-12" max-width="370">
      <v-card-title>
        <v-row>
          <v-col cols="12" align="center">
            <v-img
              :src="require('@/assets/logo.png')"
              width="200"
              alt="logo"
              class="mb-5"
            />
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" align="center">
            <v-form @submit.prevent="login" lazy-validation ref="form">
              <v-text-field
                label="E-mail"
                :prepend-icon="mdiAccount"
                :rules="[rules.required, rules.email]"
                v-model="email"
                ref="email"
                outlined
                placeholder=""
                dense
              ></v-text-field>
              <v-text-field
                label="Senha"
                :type="mostrarSenha ? 'text' : 'password'"
                :rules="[rules.required]"
                :prepend-icon="mdiLock"
                v-model="password"
                ref="password"
                outlined
                placeholder=""
                :append-icon="mostrarSenha ? mdiEyeOutline : mdiEyeOffOutline"
                @click:append="toggleMostrarSenha"
                dense
              ></v-text-field>

              <v-btn
                :loading="getLoginLoading"
                :disabled="getLoginLoading"
                block
                depressed
                color="primary"
                type="submit"
                class="mt-5"
              >
                Entrar
              </v-btn>
            </v-form>
          </v-col>
        </v-row>
      </v-card-text>
      <v-alert text prominent type="error" v-if="getLoginError">
        {{ getLoginError }}
      </v-alert>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { mdiAccount, mdiLock, mdiEyeOutline, mdiEyeOffOutline } from "@mdi/js";
import jwt from "jsonwebtoken";
export default {
  name: "LoginView",
  // mixins: [notification],
  data: () => ({
    rules: {
      required: (value) => !!value || "Obrigatório",
      email: (value) => {
        if (!value) return true;
        const pattern =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "e-mail inválido.";
      },
    },
    email: "",
    password: "",
    mdiAccount: mdiAccount,
    mdiLock: mdiLock,
    mdiEyeOutline,
    mdiEyeOffOutline,
    mostrarSenha: false,
  }),
  computed: {
    ...mapGetters(["getAuth", "getLoginError", "getLoginLoading"]),
    form() {
      return {
        email: this.email,
        password: this.password,
      };
    },
    valido() {
      return this.$refs.form.validate();
    },
  },
  methods: {
    ...mapActions(["loginPassword", "gInfo"]),
    toggleMostrarSenha() {
      this.mostrarSenha = !this.mostrarSenha;
    },
    async login() {
      if (!this.valido) return;

      try {
        await this.loginPassword({
          email: this.form.email,
          senha: this.form.password,
        });

        await this.gInfo();
        const tokenDecoded = jwt.decode(this.getAuth.access_token) || {};
        this.$nextTick(() => {
          if (
            tokenDecoded.role.includes("Leiloeiro") ||
            tokenDecoded.role.includes("Master")
          ) {
            document.title = "Painel do Leiloeiro";
            this.$router.push({ name: "PainelLeiloeiro" });
            return;
          }
          if (tokenDecoded.role.includes("Comitente")) {
            document.title = "Painel do Comitente";
            this.$router.push({ name: "PainelComitente" });
            return;
          }
        });
      } catch (error) {
        return;
      }
    },
  },
};
</script>

<style scoped>
#links {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 10px 0 10px 0;
}

#recaptcha-container {
  display: flex;
  justify-content: center;
}

a {
  text-decoration: none;
}

.background {
  background-color: #ebedf3;
}
</style>
